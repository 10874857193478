<!-- src/components/community/CommunityList.vue -->
<template>
  <v-card class="rounded-lg overflow-hidden">
    <v-toolbar flat>
      <v-icon color="primary" class="mr-2">
        mdi-bullhorn
      </v-icon>

      <span class="text-h6 font-weight-medium">Comunidades</span>
      <v-spacer />
      <CommunityForm ref="communityForm" v-if="isUserAdminOrSU" :availableUsers="availableUsers"
        @create="createCommunity" />
    </v-toolbar>


    <v-card-text class="pa-0" v-if="$props.communities.length > 0">
      <!-- Enhanced list with hover effects and better spacing -->
      <v-list class="pa-0">
        <v-list-item v-for="community in $props.communities" :key="community.id" @click="selectCommunity(community)"
          :class="{ 'selected-community': community.id == $props.selected }" class="community-item">
          <v-list-item-avatar size="32" color="primary lighten-4" class="mr-3">
            <v-icon color="primary">
              {{ community.id === 'general' ? 'mdi-earth' : 'mdi-account-group' }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="subtitle-1">
              {{ community.name }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="community.id !== 'general'" class="caption">
              {{ community.members?.length || 0 }} {{ community.members?.length == 1 ? 'Miembro' : 'Miembros' }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action v-if="community.id == $props.selected">
            <v-icon color="primary">mdi-check</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>

    <!-- Enhanced skeleton loaders -->
    <template v-else>
      <v-list-item v-for="n in 3" :key="n">
        <v-list-item-avatar>
          <v-skeleton-loader type="avatar" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-skeleton-loader type="text" />
        </v-list-item-content>
      </v-list-item>
    </template>

    <!-- Enhanced edit dialog -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card class="rounded-lg">
        <v-card-title class="headline primary--text pa-4">
          <v-icon left color="primary">mdi-pencil</v-icon>
          Editar Comunidad
        </v-card-title>

        <v-card-text class="pa-4" v-if="selectedCommunity">
          <v-form ref="editForm" @submit.prevent="updateCommunity">
            <v-text-field label="Nombre de la Comunidad" v-model="selectedCommunity.name"
              :rules="[v => !!v || 'El nombre es requerido']" outlined class="mb-4"></v-text-field>

            <v-select :items="availableUsers" item-text="displayName" item-value="id" label="Administrar Miembros"
              v-model="selectedCommunity.members" multiple chips outlined small-chips deletable-chips
              :rules="[v => v.length > 0 || 'Debe seleccionar al menos un usuario']"></v-select>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
          <v-btn @click="confirmDeleteCommunity(selectedCommunity)" icon color="error" :loading="loadingDelete"
            class="px-4">
            <v-icon>mdi-delete</v-icon>

          </v-btn>
          <v-spacer />
          <v-btn text @click="editDialog = false" class="mr-2">Cancelar</v-btn>
          <v-btn color="primary" @click="updateCommunity" :loading="loading" class="px-4">
            <v-icon left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  getFirestore,
} from "firebase/firestore";
import { arrayUnion, arrayRemove } from "firebase/firestore";


import CommunityForm from "./CommunityForm.vue";

export default {
  components: {
    CommunityForm,
  },
  name: "CommunityList",
  props: {
    communities: {
      type: Array,
      required: true,
      default: () => [],
    },
    availableUsers: {
      type: Array,
      required: true,
    },
    isUserAdminOrSU: {
      type: Boolean,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editDialog: false,
      selectedCommunity: null,
      loadingDelete: false,
      loading: false,
    };
  },
  mounted() {
    //select the first community
    if (this.$props.communities.length > 0) {
      this.selectCommunity(this.$props.communities[0]);
      this.loading = false;
    }
  },
  methods: {
    editCommunity(community) {
      this.selectedCommunity = { ...community }; // Clone the community object
      this.editDialog = true;
    },
    async updateCommunity() {
      if (this.$refs.editForm.validate()) {
        try {
          this.loading = true;
          const db = getFirestore();
          const communityRef = doc(db, "communities", this.selectedCommunity.id);

          // Get the original community data
          const originalCommunityDoc = await getDoc(communityRef);
          const originalCommunity = originalCommunityDoc.data();

          // Update the community in Firestore
          await updateDoc(communityRef, {
            name: this.selectedCommunity.name,
            members: this.selectedCommunity.members,
          });

          // Determine which users have been added or removed
          const originalMembers = originalCommunity.members || [];
          const newMembers = this.selectedCommunity.members;

          const addedMembers = newMembers.filter(
            (userId) => !originalMembers.includes(userId)
          );
          const removedMembers = originalMembers.filter(
            (userId) => !newMembers.includes(userId)
          );

          // Update users' enabledCommunities
          for (const userId of addedMembers) {
            const userRef = doc(db, "users", userId);
            await updateDoc(userRef, {
              enabledCommunities: arrayUnion(this.selectedCommunity.id),
            });
          }

          for (const userId of removedMembers) {
            const userRef = doc(db, "users", userId);
            await updateDoc(userRef, {
              enabledCommunities: arrayRemove(this.selectedCommunity.id),
            });
          }

          // Emit the updated community
          this.$emit("update", { ...this.selectedCommunity });
          this.editDialog = false;
          this.$notify({
            title: "Comunidad actualizada",
            text: "La comunidad ha sido actualizada exitosamente",
            type: "success",
          });
        } catch (error) {
          console.error("Error updating community:", error);
          this.$notify({
            title: "Error",
            text: "Ocurrió un error al actualizar la comunidad.",
            type: "error",
          });
        }
        this.loading = false;
        this.editDialog = false;
      }
    },
    async confirmDeleteCommunity(community) {
      const confirm = await this.$confirm(
        `¿Está seguro de que desea eliminar la comunidad "${community.name}"?`,
        {
          color: "error",
          title: "Eliminar Comunidad",
          buttonTrueText: "Eliminar",
          buttonFalseText: "Cancelar",
        }
      );
      if (confirm) {
        await this.deleteCommunity(community);
      }
    },
    async deleteCommunity(community) {
      try {
        this.loadingDelete = true;
        const db = getFirestore();
        const communityRef = doc(db, "communities", community.id);

        // Delete the community from Firestore
        await deleteDoc(communityRef);

        // Remove community from users' enabledCommunities
        for (const userId of community.members) {
          const userRef = doc(db, "users", userId);
          await updateDoc(userRef, {
            enabledCommunities: arrayRemove(community.id),
          });
        }

        this.$emit("delete", community.id);
        this.$notify({
          title: "Comunidad eliminada",
          text: "La comunidad ha sido eliminada exitosamente",
          type: "success",
        });
      } catch (error) {
        console.error("Error deleting community:", error);
        this.$notify({
          title: "Error",
          text: "Ocurrió un error al eliminar la comunidad.",
          type: "error",
        });
      }
      this.loadingDelete = false;

      this.editDialog = false;


    },
    selectCommunity(community) {
      this.$emit("select", community);
    },
    createCommunity(community) {
      this.$emit("create", community);
    },
  },
};
</script>

<style scoped>
.community-item:hover {
  background-color: rgba(var(--v-primary-base), 0.05);
}

.selected-community {
  background-color: rgba(var(--v-primary-base), 0.1);
}

.v-list-item__avatar {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
