<template>
    <v-card elevation="0">
        <v-card-text class="pa-0">

            <v-stepper v-model="step" class="elevation-0">

                <v-stepper-header v-show="false">
                    <v-stepper-step v-for="(question, i) in stepperQuestions" :key="i"
                        :complete="question.rating ? true : false" :step="i + 1">
                        <v-divider v-if="i < stepperQuestions.length - 1"></v-divider>
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items class="elevation-0">
                    <v-stepper-content class="pa-0 elevation-0" v-for="(question, i) in stepperQuestions" :key="i"
                        :step="i + 1">
                        <v-card width="100%" elevation="0">
                            <v-card-text>
                                <v-form ref="form">
                                    <p class="text-h5"> {{ question.question }}</p>
                                    <v-rating v-model="question.rating" :rules="question.rules" required length="5"
                                        :color="returnColor(question.rating)" class="text-center" large
                                        @input="step < stepperQuestions.length ? step++ : ''" background-color="grey"
                                        v-if="!question.last"></v-rating>

                                    <v-textarea v-else hide-details="" class="mx-4 rounded-lg"
                                        prepend-inner-icon="mdi-comment" auto-grow filled rounded
                                        v-model="question.comment" label="Comentario" :rows="1"></v-textarea>

                                </v-form>
                            </v-card-text>

                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card-text>
        <v-card-actions>

            <v-btn text @click="$emit('hideModal')" v-show="step == 1">
                <v-icon>mdi-chevron-left</v-icon>
                Volver
            </v-btn>


            <v-btn text @click="step--" v-show="step > 1">
                <v-icon>mdi-chevron-left</v-icon>
                Atrás
            </v-btn>




            <v-spacer></v-spacer>

            <v-btn @click="submitSurvey" class="rounded-lg" color="success" :loading="loading"
                v-show="step == stepperQuestions.length">
                <v-icon left>mdi-check</v-icon>
                Finalizar

            </v-btn>


            <v-btn text @click="step++"
                v-show="!(step === stepperQuestions.length) && stepperQuestions[step - 1].rating">
                Siguiente
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>

            <!--  <v-btn color="primary darken-2" rounded @click="submitSurvey" :loading="loading" large
                :disabled="!question.rating">
                <v-icon left class="mr-2">mdi-send</v-icon>
                Completar encuesta
            </v-btn> -->
        </v-card-actions>

    </v-card>

</template>



<script>
import { collection, addDoc, getFirestore, serverTimestamp } from "firebase/firestore";
import { updateDoc, doc } from "firebase/firestore";


const db = getFirestore();

export default {
    computed: {
        filteredSurveys() {
            if (this.onlyUnseen) {
                return this.surveys.filter((survey) => !survey.seen);
            } else {
                return this.surveys;
            }
        },
    },
    data() {
        return {
            step: 1,
            stepperQuestions: [
                {
                    question: "¿Cómo calificaría su experiencia con el servicio brindado?",
                    rating: null,
                    comment: null,
                    rules: [
                        (v) => !!v || "El rating es requerido",
                    ],
                    rulesComment: [
                        (v) => !!v || "El comentario es requerido",
                        (v) => (v && v.length <= 500) || "El comentario debe tener menos de 500 caracteres",
                    ],
                },
                {
                    question: "¿Cómo calificaría la atención recibida por parte de los entrenadores?",
                    rating: null,
                    comment: null,
                    rules: [
                        (v) => !!v || "El rating es requerido",
                    ],
                    rulesComment: [
                        (v) => !!v || "El comentario es requerido",
                        (v) => (v && v.length <= 500) || "El comentario debe tener menos de 500 caracteres",
                    ],
                },
                {
                    question: "¿Cómo calificaría el estado de los equipos?",
                    rating: null,
                    comment: null,
                    rules: [
                        (v) => !!v || "El rating es requerido",
                    ],
                    rulesComment: [
                        (v) => !!v || "El comentario es requerido",
                        (v) => (v && v.length <= 500) || "El comentario debe tener menos de 500 caracteres",
                    ],
                },
                {
                    question: "¿Cómo calificaría la limpieza del lugar?",
                    rating: null,
                    comment: null,
                    rules: [
                        (v) => !!v || "El rating es requerido",
                    ],
                    rulesComment: [
                        (v) => !!v || "El comentario es requerido",
                        (v) => (v && v.length <= 500) || "El comentario debe tener menos de 500 caracteres",
                    ],
                },
                {
                    question: "¿Algun comentario adicional? (Opcional)",
                    last: true,
                    rating: null,
                    comment: null,
                    rules: [
                        (v) => !!v || "El rating es requerido",
                    ],
                    rulesComment: [
                        (v) => !!v || "El comentario es requerido",
                        (v) => (v && v.length <= 500) || "El comentario debe tener menos de 500 caracteres",
                    ],
                },
            ],




            onlyUnseen: true,
            headers: [
                { text: "Usuario", value: "user" },
                //{ text: "Comentario", value: "comment" },
                { text: "Rating", value: "rating" },
                { text: "Fecha", value: "date" },
            ],
            loading: false,
            surveyDetailsDialog: false,
            selectedSurvey: null,
            comment: null,
            rating: null,
            surveys: [],
            isAdmin: this.$store.state.Auth.token.claims.type == 'superuser',
            rules: [
                (v) => !!v || "El comentario es requerido",
                (v) => (v && v.length <= 500) || "El comentario debe tener menos de 500 caracteres",
            ],
            rulesRating: [
                (v) => !!v || "El rating es requerido",
            ],
        };
    },
    created() {
    },
    mounted() {
        this.$vuetify.goTo(0);
    },
    methods: {
        async submitSurvey() {
            let userId = this.$store.state.Auth.token.claims.user_id;
            let userType = this.$store.state.Auth.token.claims.type;

            this.loading = true
            const timestamp = serverTimestamp();

            // Calculate average rating (excluding last question which is comments)
            const ratings = this.stepperQuestions
                .filter(q => !q.last && typeof q.rating === 'number')
                .map(q => q.rating)
            const averageRating = ratings.length > 0
                ? ratings.reduce((a, b) => a + b, 0) / ratings.length
                : 0

            // Get comment from last question
            const comment = this.stepperQuestions[4]?.comment || null

            // Map questions to new schema
            const questions = {
                clases: this.stepperQuestions[0]?.rating || 0,      // "Experiencia con el servicio"
                atencion: this.stepperQuestions[1]?.rating || 0,    // "Atención recibida"
                instalaciones: this.stepperQuestions[2]?.rating || 0, // "Estado de los equipos"
                limpieza: this.stepperQuestions[3]?.rating || 0,    // "Limpieza del lugar"
            }

            // Determine if survey is omitted (no ratings)
            const isOmitted = comment == null && questions.clases == 0 && questions.atencion == 0 && questions.instalaciones == 0 && questions.limpieza == 0

            await addDoc(collection(db, `surveys`), {
                date: timestamp,
                userId: userId,
                userName: userType, // Consider getting actual user name if available
                averageRating,
                questions,
                sentiment: null, // Will be set by cloud function
                comment,
                omitted: isOmitted,
                seen: false,
                userType // Keep this if needed for other features
            });

            this.loading = false

            this.$notify({
                group: "feedback",
                type: "success",
                title: "Encuesta completada",
                text: "Gracias por su colaboración",
            });

            this.$emit('hideAll');
        },
        returnColor(rating) {

            if (rating == 1) {
                return "red darken-2";
            } else if (rating == 2) {
                return "orange darken-2";
            } else if (rating == 3) {
                return "grey lighten-2";
            } else if (rating == 4) {
                return "green darken-2";
            } else if (rating == 5) {
                return "yellow darken-1";
            }
        }
    },
};
</script>
