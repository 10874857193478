<template>
    <v-snackbar class="mb-6" v-model="show" :timeout="timeout" :color="color" :top="false">


        {{ title }}
        <template v-if="text">
            <br>
            {{ text }}

        </template>



        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="show = false">
                cerrar
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    data() {
        return {
            show: true,
            title: '',
            text: '',
            color: 'success',
            timeout: 80000, // Default timeout
        }
    },
    methods: {
        notify(options) {
            this.title = options.title || null;
            this.text = options.text || null;
            this.color = options.type || 'success';
            this.timeout = options.type === 'error' ? -1 : (options.timeout || 4000);

            if (!options.title) {
                if (this.color == 'success') {
                    this.title = 'Éxito';
                } else if (this.color == 'error') {
                    this.title = 'Error';
                } else if (this.color == 'warning') {
                    this.title = 'Advertencia';
                } else if (this.color == 'info') {
                    this.title = 'Información';
                }
            }

            this.show = true;
        },
    },
}
</script>
