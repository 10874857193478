<template>
  <v-form ref="form" v-model="isFormValid" v-if="isUserAdminOrSU">
    <v-card class="post-form-card rounded-lg overflow-hidden" elevation="1">
      <!-- Content input area -->
      <v-container class="px-4 py-3">
        <PostInput id="postForm" v-model="post.content" @addYoutubeLink="(e) => post.youtubeLinks.push(e)"
          @addSpotifyLink="(e) => post.spotifyLinks.push(e)" class="mb-2" />
      </v-container>

      <!-- Media preview carousel -->
      <v-slide-y-transition>
        <MediaCarousel v-if="mediaLength" :attachments="post.attachments" :youtubeLinks="post.youtubeLinks"
          :spotifyLinks="post.spotifyLinks" @deleteAttachment="removeAttachment" @deleteYoutubeLink="deleteYoutubeLink"
          @deleteSpotifyLink="deleteSpotifyLink" class="mb-3" />
      </v-slide-y-transition>

      <!-- Action buttons -->
      <v-divider></v-divider>
      <v-card-actions class="pa-3">
        <AttachmentsInput v-model="post.attachments" class="mr-2" />

        <v-spacer />

        <v-btn v-if="value" class="mx-2" color="error" outlined rounded small @click="cancelEdit()">
          <v-icon left> mdi-close </v-icon>
          Cancelar
        </v-btn>

        <v-btn class="mx-2" color="primary" :loading="loading.confirm"
          :disabled="!post.content || (post.content && !post.content.trim())" rounded @click="postContent">
          <v-icon left>
            {{ value ? "mdi-pencil" : "mdi-send" }}
          </v-icon>
          {{ value ? "Actualizar" : "Publicar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { collection, doc, updateDoc, getFirestore, serverTimestamp, Timestamp, addDoc } from "firebase/firestore";
import { ref as storageRef, getDownloadURL, uploadBytesResumable, getStorage, deleteObject } from "firebase/storage";
import VisibilityDisplay from "../visibility/VisibilityDisplay.vue";
import VisibilityForm from "../visibility/VisibilityForm.vue";
import PostInput from "./PostInput.vue";
import AttachmentsInput from "./AttachmentsInput.vue";
import { v4 } from "uuid";
import MediaCarousel from "../media/MediaCarousel.vue";

export default {
  name: "PostForm",

  components: {
    VisibilityDisplay,
    VisibilityForm,
    PostInput,
    AttachmentsInput,
    MediaCarousel,
  },

  props: {
    availableUsers: Array,
    value: Object,
    selectedCommunity: String
  },

  data() {
    return {
      loading: {
        confirm: false,
      },
      post: {
        content: null,
        attachments: [],
        youtubeLinks: [],
        spotifyLinks: [],
        visibility: ["all"],
      },
      isFormValid: false,
      dialogs: {
        visibility: false,
      },
      dialog: false,
      originalAttachments: [], // To store the original attachments
      removedAttachments: [], // To store removed attachments
    };
  },

  mounted() {
    this.loadData();
  },

  computed: {
    isUserAdminOrSU() {
      const role = this.$store.state.Auth.token.claims.type;
      return role && ["admin", "superuser"].includes(role);
    },
    mediaLength() {
      const media = [
        ...this.post.attachments,
        ...this.post.youtubeLinks,
        ...this.post.spotifyLinks,
      ];

      return media.length;
    },
  },

  methods: {
    loadData() {
      if (this.value) {
        this.post = { ...this.value };
        this.originalAttachments = [...this.value.attachments]; // Store the original attachments
      }
    },
    resetForm() {
      this.$refs.form.reset();

      if (this.value) {
        this.loadData();
      } else {
        this.post = {
          content: null,
          attachments: [],
          youtubeLinks: [],
          spotifyLinks: [],
          visibility: ["all"],
        };
      }
    },
    async postContent() {
      if (this.$refs.form.validate()) {
        try {
          this.loading.confirm = true;
          if (this.value) {
            await this.updatePost();
          } else {
            await this.createPost();
            this.resetForm();
          }

          const message = this.value ? "actualizado" : "creado";
          this.$notify({
            title: `Post ${message}`,
            text: `Tu post ha sido ${message} con éxito`,
            type: "success",
          });
        } catch (e) {
          console.error(e);
          this.$notify({
            title: "Error",
            text: `Ha ocurrido un error al ${this.value ? "actualizar" : "crear"} el post`,
            type: "error",
          });
        } finally {
          this.loading.confirm = false;
        }
      }
    },
    async createPost() {
      try {
        const db = getFirestore();
        const storage = getStorage();

        let post = {
          ...this.post,
          content: this.post.content.trim(),
          created: {
            date: serverTimestamp(),
            user: this.$store.state.Auth.token.claims.user_id,
            displayName: this.$store.state.Auth.token.claims.name,
          },
          updated: {
            date: null,
            user: null,
          },
          likes: [],
          communityId: this.$props.selectedCommunity,
        };

        const docRef = await addDoc(collection(db, "posts"), post);

        await Promise.all(
          this.post.attachments.map(async (attachment, index) => {
            const fileExtension = attachment.name.split(".").pop();
            const storageReference = storageRef(storage, `attachments/${docRef.id}/${attachment.name}`);

            let blobURL = attachment.url;

            // Convert to file
            const response = await fetch(blobURL);
            const blob = await response.blob();
            const file = new File([blob], attachment.name, { type: attachment.type });

            const uploadTask = uploadBytesResumable(storageReference, file, {
              contentType: attachment.type,
            });
            await uploadTask;
            const downloadURL = await getDownloadURL(storageReference);
            this.post.attachments[index] = {
              name: attachment.name,
              url: downloadURL,
              type: attachment.type,
            };
          })
        );

        await updateDoc(docRef, {
          attachments: this.post.attachments,
        });

        this.$emit("create", {
          ...this.post,
          created: {
            date: Timestamp.now(),
            user: this.$store.state.Auth.token.claims.user_id,
            displayName: this.$store.state.Auth.token.claims.name,
          },
          likes: [],
          id: docRef.id,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async updatePost() {
      try {
        const db = getFirestore();
        const storage = getStorage();
        const docRef = doc(db, "posts", this.value.id);

        // Delete removed attachments from storage
        await Promise.all(
          this.removedAttachments.map(async (attachment) => {
            const storageReference = storageRef(storage, attachment.url);
            await deleteObject(storageReference);
          })
        );

        // Only upload new attachments that are blobs
        await Promise.all(
          this.post.attachments.map(async (attachment, index) => {
            if (attachment.url.startsWith('blob:')) {
              const fileExtension = attachment.name.split(".").pop();
              const storageReference = storageRef(storage, `attachments/${docRef.id}/${attachment.name}`);

              let blobURL = attachment.url;

              //convert to file
              const response = await fetch(blobURL);
              const blob = await response.blob();
              const file = new File([blob], attachment.name, { type: attachment.type });

              const uploadTask = uploadBytesResumable(storageReference, file, {
                contentType: attachment.type,
              });
              await uploadTask;
              const downloadURL = await getDownloadURL(storageReference);
              attachment.url = downloadURL;
            }
          })
        );

        await updateDoc(docRef, {
          ...this.post,
          updated: {
            date: serverTimestamp(),
            user: this.$store.state.Auth.token.claims.user_id,
          },
        });

        this.$emit("update", {
          ...this.post,
        });
      } catch (error) {
        console.error(error);
      }
    },
    removeAttachment(index) {
      const attachment = this.post.attachments[index];
      this.removedAttachments.push(attachment);
      this.post.attachments.splice(index, 1);
    },
    deleteYoutubeLink(index) {
      this.post.youtubeLinks.splice(index, 1);
    },
    deleteSpotifyLink(index) {
      this.post.spotifyLinks.splice(index, 1);
    },
    async cancelEdit() {
      const confirm = await this.$confirm(
        "¿Está seguro de que desea cancelar los cambios?",
        {
          color: "error",
          title: "Advertencia",
          icon: "mdi-alert-circle",
          buttonTrueText: "Confirmar",
        }
      );

      if (!confirm) return;

      this.$emit("cancelEdit");
    },
  },
};
</script>

<style scoped>
/* .post-form-card {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
  transition: box-shadow 0.3s ease;
}

.post-form-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
} */
</style>
