import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import { logAuditEvent } from '@/error/audit.js';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/landing.vue"),
    meta: {
      requiresAuth: false,
      title: "Sportfit App",
    },
  },
  {
    path: "/reportar-problema",
    name: "reportIssue",
    component: () => import("@/views/ReportIssue.vue"),
    meta: { requiresAuth: true, title: "Reportar problema" },
  },
  {
    path: "/inicio",
    name: "home",
    component: () => import("@/views/MobileHome.vue"),
    meta: { requiresAuth: true, title: "Inicio" },
  },
  {
    path: "/progreso",
    name: "progress",
    component: () => import("@/components/Weights.vue"),
    meta: {
      requiresAuth: true,
      title: "Progreso",
    },
  },
  {
    path: "/perfil",
    name: "user",
    component: () => import("@/views/UserProfile.vue"),
    meta: {
      requiresAuth: true,
      title: "Perfil",
    },
  },
  {
    path: "/perfil/horarios",
    name: "user",
    component: () => import("@/components/workforce/WorkforceCalendar.vue"),
    meta: {
      requiresAuth: true,
      onlyAdminAndTrainer: true,
      title: "Mis Horarios",
    },
  },


  

  {
    path: "/cronometro",
    name: "Chrono",
    component: () => import("@/views/ChronoApp.vue"),
    meta: { requiresAuth: true, title: "Cronómetro" },
  },
  /*  {
    path: "/import",
    name: "import",
    component: () => import("@/components/UserImport.vue"),
    meta: {
      requiresAuth: true,
      onlyAdmin: true,
    },
  }, */
  /*  {
    path: "/stats",
    name: "stats",
    component: () => import("@/components/RealtimeStatsManager.vue"),
    meta: { requiresAuth: true },
  }, */
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      requiresAuth: false,
      title: "Iniciar sesión",
    },
  },

   {
    path: "/comunidad",
    name: "Community",
    component: () => import("@/views/Feed.vue"),
    meta: { requiresAuth: true, title: "Comunidad" },
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/Admin.vue"),
    meta: {
      requiresAuth: true,
      onlyAdmin: true,
      title: "Administrador",
    },
  },
  {
    path: "/usuarios",
    name: "users",
    component: () => import("@/views/Users.vue"),
    meta: {
      requiresAuth: true,
      title: "Usuarios",
    },
  },
  {
    path: "*",
    name: "errorpage",
    component: () => import("@/views/ErrorPage.vue"),
    meta: {
      requiresAuth: false,
      title: "Error 404",
    },
  },
  {
    path: "/clases",
    name: "reservationsUser",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true,
      title: "Reservas de clases",
    },
  },
 
  {
    path: "/tareas",
    name: "Tasks",
    component: () => import("@/views/Tasks.vue"),
    meta: {
      requiresAuth: true,
      title: "Tareas",
      onlyAdmin: true,
    },
  },
  {
    path: "/ingresos-egresos",
    name: "IngresosEgresos",
    component: () => import("@/views/IngressEgress.vue"),
    meta: {
      requiresAuth: true,
      title: "Ingresos y Egresos",
      onlyAdmin: true,
    },
  },
  {
    path: "/checkin",
    name: "checkin",
    component: () => import("@/components/QRreader2.vue"),
    meta: {
      requiresAuth: true,
      title: "Check-in",
      onlyAdminAndTrainer: true,
    },
  },
  {
    path: "/suscripcion",
    name: "suscripcion",
    component: () => import("@/views/Suscripcion.vue"),
    meta: {
      requiresAuth: true,
      title: "Suscripcion Vencida",
    },
  },
  {
    path: "/programas",
    name: "Routines",
    component: () => import("@/components/Planification.vue"),

    meta: {
      title: "Programas",
      requiresAuth: true,
      onlyAdminAndTrainer: true,
    },
  },
  {
    path: "/entrenamiento",
    name: "plan",
    component: () => import("@/views/PlanView.vue"),
    meta: {
      requiresAuth: true,
      title: "Rutinas",
    },
  },

  {
    path: "/entrenamiento/actividad",
    name: "actividad",
    component: () => import("@/views/Activity.vue"),
    meta: {
      requiresAuth: true,
      title: "Actividad",
    },
  },



  {
    path: "/glosario",
    name: "Exercises",
    component: () => import("@/views/ExerciseCRUD.vue"),
    meta: {
      requiresAuth: true,
      title: "Glosario",
      onlyAdmin: true,
    },
  },
  {
    path: "/analisis",
    name: "Analisis",
    component: () => import("@/views/Reports.vue"),
    meta: {
      requiresAuth: true,
      title: "Análisis de datos",
      onlyAdmin: true,
    },
  },
  {
    path: "/suscripciones",
    name: "Subscriptions",
    component: () => import("@/views/Subscriptions.vue"),
    meta: {
      requiresAuth: true,
      title: "Suscripciones",
      onlyAdmin: true,
    },
  },

  {
    path: "/checkout",
    name: "checkout",
    component: () => import("@/components/checkout/MercadoPago.vue"),
    meta: {
      requiresAuth: true,
      title: "Pago",
    },
  },

  {
    path: "/pago",
    name: "payment",
    component: () => import("@/components/checkout/MercadoPago.vue"),
    meta: {
      requiresAuth: true,
      title: "Pago",
    },
  },
  {
    path: "/sub",
    name: "subscription",
    component: () => import("@/components/checkout/SubscriptionsMercadoPago.vue"),
    meta: {
      requiresAuth: true,
      title: "Suscripción",
    },
  },
  //same for pendiente and fallido
  {
    path: "/pendiente",
    name: "checkoutpendiente",
    component: () => import("@/components/checkout/MPpending.vue"),
    meta: {
      requiresAuth: true,
      title: "Pago pendiente",
    },
  },
  {
    path: "/fallido",
    name: "checkoutfallido",
    component: () => import("@/components/checkout/MPfailed.vue"),
    meta: {
      requiresAuth: true,
      title: "Pago fallido",
    },
  },

  {
    path: "/recursos",
    name: "resources",
    component: () => import("@/views/Resources.vue"),
    meta: {
      requiresAuth: true,
      title: "Recursos",
      onlyAdmin: true,
    },
  },

  {
    path: "/ajustes",
    name: "config",
    component: () => import("@/views/Config.vue"),
    meta: {
      requiresAuth: true,
      title: "Ajustes",
      onlyAdmin: true,
    },
  },

  {
    path: "/eval",
    name: "eval",
    component: () => import("@/components/evaluations/tubieja.vue"),
    meta: {
      requiresAuth: true,
      title: "Evaluaciones",
      onlyAdmin: true,
    },
  },
  {
    path: "/evaluaciones/calendario",
    name: "Evaluations",
    component: () => import("@/views/EvaluationsHistory.vue"),
    meta: {
      requiresAuth: true,
      title: "Evaluaciones",
      onlyAdmin: true,
    },
  },

  {
    path: "/inventario-tienda",
    name: "inventario-tienda",
    component: () => import("@/views/Store.vue"),
    meta: {
      requiresAuth: true,
      title: "Inventario & Tienda",
    },
  },
  {
    path: '/punto-venta/:userId?',
    name: 'punto-venta',
    component: () => import("@/views/PuntoVenta.vue"),
  },

  {
    path: "/logs",
    name: "logs",
    component: () => import("@/views/Logs.vue"),
    meta: {
      requiresAuth: true,
      title: "Logs",
      onlyAdmin: true,
    },
  },

  
  {
    path: "/evaluaciones/formularios",
    name: "forms",
    component: () => import("@/components/forms/FormManager.vue"),
    meta: {
      requiresAuth: true,
      title: "Captura de datos",
    },
  },

  {
    path: "/actividad",
    name: "Activity",
    component: () => import("@/components/activity/UserActivity.vue"),
    meta: {
      requiresAuth: true,
      title: "Actividad",
    },
  },

  {
    path: '/admin/fix-dates',
    name: 'FixUserDates',
    component: () => import('@/components/admin/FixUserDates.vue'),
    meta: {
      requiresAuth: true,
      requiresSuperuser: true
    }
  },

  {
    path: '/admin/fix-payments',
    name: 'FixPayments',
    component: () => import('@/components/admin/FixPaymentTotals.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true  // If you have admin-only routes
    }
  },

  {
    path: '/admin/fix-surveys',
    name: 'FixSurveys',
    component: () => import('@/components/admin/FixSurveySchema.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },

  {
    path: '/negocio/suscripciones-mercadopago',
    name: 'SubscriptionPlansManager',
    component: () => import('@/components/checkout/SubscriptionPlansManager.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },

  {
    path: '/admin/mercadopago-events',
    name: 'MercadoPagoEvents',
    component: () => import('@/components/admin/MercadoPagoEvents.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true // If you have admin role checking
    }
  },


  {
    path: "/agenda/reservas",
    name: "adminclases",
    component: () => import("@/views/ScheduleView.vue"),
    meta: {
      requiresAuth: true,
      title: "Reservas & Evaluaciones",
      onlyAdminAndTrainer: true,
    },
  },

  {
    path: "/agenda/configuracion",
    name: "adminconfig",
    component: () => import("@/components/Schedules.vue"),
    meta: {
      requiresAuth: true,
      title: "Configuración",
      onlyAdmin: true,
    },
  },
  {
    path: "/agenda/suscripciones",
    name: "adminSuscripciones",
    component: () => import("@/views/Subscriptions.vue"),
    meta: {
      requiresAuth: true,
      title: "Suscripciones",
      onlyAdmin: true,
    },
  },

  {
    path: "/negocio/tareas",
    name: "adminTareas",
    component: () => import("@/views/Tasks.vue"),
    meta: {
      requiresAuth: true,
      title: "Tareas",
      onlyAdmin: true,
    },
  },
  {
    path: "/negocio/analisis",
    name: "analisis",
    component: () => import("@/views/Reports.vue"),
    meta: {
      requiresAuth: true,
      title: "Análisis",
      onlyAdmin: true,
    },
  },
  {
    path: "/negocio/email",
    name: "email",
  component: () => import("@/components/templates/EmailTemplates.vue"),
    meta: {
      requiresAuth: true,
      title: "Email",
      onlyAdmin: true,
    },
  },
  {
    path: "/negocio/control-acceso",
    name: "control-acceso",
    component: () => import("@/components/QRreader2.vue"),
    meta: {
      requiresAuth: true,
      title: "Control de Acceso",
      onlyAdmin: true,
    },
  },


  {
    path: "/finanzas/ingresos-gastos",
    name: "IngresosGastos",
    component: () => import("@/views/IngressEgress.vue"),
    meta: {
      requiresAuth: true,
      title: "Ingresos y Gastos",
      onlyAdmin: true,
    },
  },
  {
    path: "/finanzas/pagos-subscripcion",
    name: "PagosSubscripcion",
    component: () => import("@/views/Payments.vue"),
    meta: {
      requiresAuth: true,
      title: "Pagos de Suscripción",
      onlyAdmin: true,
    },
  },
  {
    path: "/finanzas/resumen-financiero",
    name: "ResumenFinanciero",
    component: () => import("@/components/reports/FinancialSummary.vue"),
    meta: {
      requiresAuth: true,
      title: "Resumen Financiero",
      onlyAdmin: true,
    },
  },

  {
    path: '/negocio/workforce',
    name: 'Workforce',
    component: () => import('@/views/Workforce.vue'),
    meta: {
      requiresAuth: true,
      title: "Fuerza de Trabajo",
      onlyAdmin: true
    }
  },

  {
    path: '/calendario',
    name: 'Workforce',
    component: () =>import('@/views/Workforce.vue'),
    meta: {
      requiresAuth: true,
      title: "Mi Calendario",
    }
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresOnlyAdmin = to.matched.some((record) => record.meta.onlyAdmin);
  const requiresOnlyAdminAndTrainer = to.matched.some(
    (record) => record.meta.onlyAdminAndTrainer
  );
  const userClaims = store.state.Auth.token;
  const isWebRTCSupported =
    typeof navigator.getUserMedia != "undefined" ||
    typeof window.RTCPeerConnection != "undefined";
  let WebRTCSupported = false;
  if (isWebRTCSupported) {
    WebRTCSupported = true;
  }

  if (
    /CriOS/i.test(navigator.userAgent) &&
    /iphone|ipod|ipad/i.test(navigator.userAgent)
  ) {
    WebRTCSupported = false;
  }

  if (requiresAuth && !userClaims) {
    next("/login");
    return;
  }

  let checkList = ["index", "login", "signup"];

  if (checkList.includes(to.name) && userClaims) {
    next("/inicio");
    return;
  }

  if (
    (to.name === "admin" ||
      to.name === "users" ||
      to.name === "import" ||
      to.name === "Schedules") &&
    userClaims &&
    !userClaims.claims.type
  ) {
    next("error");
    return;
  }

  if (to.name === "admin" && userClaims && !userClaims.claims.type) {
    next("/inicio");
    return;
  }

  if (
    requiresOnlyAdminAndTrainer &&
    userClaims &&
    userClaims.claims.type &&
    userClaims.claims.type != "admin" &&
    userClaims.claims.type != "superuser" &&
    userClaims.claims.type != "entrenador"
  ) {
    next("/inicio");
    return;
  }

  if (
    requiresOnlyAdmin &&
    userClaims &&
    userClaims.claims.type &&
    userClaims.claims.type != "admin" &&
    userClaims.claims.type != "superuser"
  ) {
    next("/inicio");
    return;
  }

  if (requiresAuth && !userClaims) {
    next("/login");
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title + " - Sportfit"
      : "Sportfit App";
    
    // Log navigation for admin/superuser
    const userClaims = store.state.Auth.token;
    if (userClaims && userClaims.claims.type && 
       (userClaims.claims.type === 'admin' || userClaims.claims.type === 'superuser')) {
      logAuditEvent("navigation",userClaims.claims.user_id,{
          from: from.path,
          to: to.path,
        });
    }
  });
});

export default router;
